import 'shared/css/paf-bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.js';

import notFoundTemplate from './views/placeholder.pug';

import 'shared/css/login.css';

class NotFoundPage {
  // here we add the container and mount the necessary pieces to the proper container divs
  mount(mountSelector) {
    $(mountSelector).html(notFoundTemplate);
  }
}

// this runs at startup and gets everything going
$(() => {
  const notFoundPage = new NotFoundPage();
  notFoundPage.mount('.page-wrapper');
});
